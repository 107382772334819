import { useDayjs } from "#dayjs"; // not need if you are using auto import
const dayjs = useDayjs();

export const useFilters = function () {
  function formatDate(date) {
    const formattedDate = dayjs(date).format("YYYY-MM-DD");
    return formattedDate;
  }

  function getMonth(date) {
    const formattedDate = dayjs(date).format("MMMM");
    return formattedDate;
  }

  function getYear(date) {
    const formattedDate = dayjs(date).format("YYYY");
    return formattedDate;
  }

  function convertToDatepickerFormat(date) {
    const formattedDate = dayjs(date).format("YYYY-MM-DDThh:mm");
    return formattedDate;
  }

  function formatDateAndTime(date) {
    const formattedDate = dayjs(date).format("YYYY-MM-DD HH:mm");
    return formattedDate;
  }

  function formatCurrency(currency, amount, maximumFractionDigits = 2) {
    switch (currency) {
      case "USDT":
      case "BUSD":
      case "DAI":
        currency = "USD";
        break;
      default:
        break;
    }
    return new Intl.NumberFormat(navigator.language, {
      style: "currency",
      currency: currency,
      currencyDisplay: "narrowSymbol",
      minimumFractionDigits: 2,
      maximumFractionDigits: maximumFractionDigits,
    }).format(amount);
  }

  function calculatePnl(trade, cmp) {
    if (cmp == 0 || !cmp) {
      return {
        pnl: trade.pnl,
        percentage: trade.pnlPercentage,
      };
    }

    const priceChange = cmp - trade.price;
    return {
      pnl: priceChange * trade.amount,
      percentage: (priceChange / trade.price) * 100,
    };
  }

  return {
    formatDate,
    formatDateAndTime,
    convertToDatepickerFormat,
    formatCurrency,
    getMonth,
    calculatePnl,
    getYear,
  };
};
